import { graphql } from "gatsby";
import DocumentationDetailsPage from "../../../layouts/WithSideBarPage/_detailsPage";

const Troubleshooting = (data) => {
  const { htmlAst, frontmatter } = data.data.markdownRemark;
  return (
    <DocumentationDetailsPage
      frontmatter={frontmatter}
      htmlAst={htmlAst}
      allPagesComments={data.data.allPagesComments}
    />
  );
};

export default Troubleshooting;

export const query = graphql`
  query getTroubleshooting(
    $path: String!
    $siteLanguage: String!
    $commentsPage: String!
    ) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        bannerTitle
        bannerSubTitle
        sidebarChildrenTitle
        href
        featureToolkit
        featureTitleToolkit
        featureButtonTextToolkit
        path
      }
    }
    allPagesComments(
      sort: { fields: id__normalized, order: ASC }
      filter: { pageKey: { eq: $commentsPage } }
    ) {
      nodes {
        id__normalized
        author
        date(formatString: "MMMM D, YYYY [at] HH:mm", locale: $siteLanguage)
        content
        parentId
        avatarUrl
      }
    }
  }
`;
